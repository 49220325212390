import * as React from "react";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { titleize } from "app/utils/string";
import { Avatar, Button, Input } from "@nextui-org/react";
import classNames from "classnames";
import { useQuery } from "app/utils/useQuery";

export interface OrderCardProps {
  paramShopKey: string;
  serviceProvider: any;
  mainServiceChoice: any;
  additionalServices: any[];
  subtotal: string;
  onHandleSubmit: (event?: any) => void;
  ctaText?: string;
  showTip?: boolean;
  parentHandleTipSelect?: (percentage: number) => void;
  disableCta?: boolean;
}

const tipPercentages = [15, 20, 25, 30];

export const OrderCard: React.FC<OrderCardProps> = ({
  paramShopKey,
  serviceProvider,
  mainServiceChoice,
  additionalServices,
  subtotal,
  onHandleSubmit,
  ctaText = "Continue",
  showTip = false,
  parentHandleTipSelect,
  disableCta = false,
}) => {
  const firstNameOfSp =
    serviceProvider?.name.split(" ")?.[0] || serviceProvider?.name;
  const lastNameFirstLetterOfSp =
    serviceProvider?.name.split(" ")?.[1]?.[0] || "";
  const [tip, setTip] = React.useState<number | null>(null);
  const [customTip, setCustomTip] = React.useState<string>("");
  const [isCustomTip, setIsCustomTip] = React.useState<boolean>(false);
  const [tipFromSubtotal, setTipFromSubtotal] = React.useState<string | null>(
    null
  );
  const query = useQuery();
  const cartParams = query.get("cart");

  const handleTipSelect = (percentage: number) => {
    if (tip === percentage) {
      setTip(null);
      setIsCustomTip(false);
      setTipFromSubtotal(null);
      parentHandleTipSelect?.(0);
    } else {
      setTip(percentage);
      setIsCustomTip(false);
      const tipAmount = (parseFloat(subtotal) * percentage) / 100;
      setTipFromSubtotal(tipAmount.toFixed(2));
      parentHandleTipSelect?.(percentage);
    }
  };

  const handleCustomTip = (value: string) => {
    const customTipValue = parseFloat(value);
    setCustomTip(value);
    if (!isNaN(customTipValue)) {
      const tipAmount = (parseFloat(subtotal) * customTipValue) / 100;
      setTipFromSubtotal(tipAmount.toFixed(2));
      parentHandleTipSelect?.(customTipValue);
    } else {
      setTipFromSubtotal(null);
      parentHandleTipSelect?.(0);
    }
  };

  const handleCustomTipClick = () => {
    if (isCustomTip) {
      setIsCustomTip(false);
      setCustomTip("");
      setTipFromSubtotal(null);
      parentHandleTipSelect?.(0);
    } else {
      setIsCustomTip(true);
      setTip(null);
      setCustomTip("");
      setTipFromSubtotal(null);
    }
  };

  return (
    <form
      className="flex flex-col gap-8 mt-10 mr-4 border border-gray-200 p-4 w-1/3 rounded shadow-lg"
      onSubmit={onHandleSubmit}
    >
      <div>
        <p className="text-xl font-bold">Your order</p>
        <p className="text-sm text-gray-400">{titleize(paramShopKey)}</p>
      </div>
      <div className="flex gap-3 items-center">
        <Avatar
          src={serviceProvider?.avatar}
          alt={`Logo of ${serviceProvider?.name}`}
          size="lg"
          radius="md"
          className="grayscale w-16"
        />
        <div className="flex justify-between w-full ">
          <div className="flex flex-col">
            <p className="font-bold">
              {firstNameOfSp && `${firstNameOfSp} ${lastNameFirstLetterOfSp}`}
            </p>
            <FadeInSlide key="mainServiceChoice?.title" direction="top">
              <p className="text-xs">{mainServiceChoice?.title}</p>
            </FadeInSlide>
          </div>
          <div className="flex flex-col items-end">
            {!!subtotal && <p className="text-sm font-bold">${subtotal}</p>}
            <p className="text-xs">{mainServiceChoice?.price}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-1 relative h-full">
        {additionalServices.map((service: any) => (
          <FadeInSlide
            key={service.id}
            direction="top"
            className="flex justify-between items-center"
          >
            <p className="text-xs">+ {service.title}</p>
            <p className="text-xs"> {service.price}</p>
          </FadeInSlide>
        ))}
        {showTip && (
          <div className="flex flex-col mt-4 gap-2">
            <div className="flex items-center justify-between mb-1">
              <p className="text-xs">Tip</p>
              {tipFromSubtotal && <p className="text-xs">${tipFromSubtotal}</p>}
            </div>
            <div className="flex gap-2 flex-wrap">
              {tipPercentages.map((percentage) => (
                <Button
                  key={percentage}
                  variant="bordered"
                  size="sm"
                  className={classNames(
                    "shadow-md border-1 text-xs overflow-visible",
                    {
                      "bg-black text-white": tip === percentage,
                    }
                  )}
                  onClick={() => handleTipSelect(percentage)}
                >
                  {percentage}%
                </Button>
              ))}
              <Button
                key={"custom percentage"}
                variant="bordered"
                size="sm"
                className={classNames("shadow-md border-1 text-xs", {
                  "bg-black text-white": isCustomTip,
                })}
                onClick={handleCustomTipClick}
              >
                Custom
              </Button>
            </div>
            {isCustomTip && (
              <Input
                placeholder="Enter custom tip %"
                size="sm"
                endContent="%"
                isClearable
                value={customTip}
                onChange={(e) => handleCustomTip(e.target.value)}
                className="mt-2"
                min="0"
                max="100"
              />
            )}
          </div>
        )}
        {cartParams && (
          <div className="flex flex-col mt-4 gap-4">
            <Input
              type="text"
              validationBehavior="native"
              classNames={{
                inputWrapper:
                  "rounded-sm bg-transparent shadow border-1 border-zinc-200",
              }}
              isRequired
              name="name"
              labelPlacement="outside"
              label="Name"
              placeholder="Enter your name"
              size="sm"
              className="mt-4"
              defaultValue={
                process.env.NODE_ENV === "development" ? "Testy McTest" : ""
              }
            />
            <Input
              type="email"
              validationBehavior="native"
              classNames={{
                inputWrapper:
                  "rounded-sm bg-transparent shadow border-1 border-zinc-200",
              }}
              isRequired
              name="email"
              labelPlacement="outside"
              label="Email"
              placeholder="Enter your email"
              size="sm"
              className="mt-4"
              defaultValue={
                process.env.NODE_ENV === "development"
                  ? "y1fse@iconmal.com"
                  : ""
              }
            />
            {/* <Input
              validationBehavior="native"
              placeholder="Enter your phone number"
              size="sm"
              isRequired
              name="phone"
              labelPlacement="outside"
              label="Phone"
              classNames={{
                inputWrapper:
                  "rounded-sm bg-transparent shadow border-1 border-zinc-200",
              }}
              className="mt-4"
            /> */}
          </div>
        )}
        {subtotal && (
          <FadeInSlide
            key={"subtotal"}
            direction="top"
            className="absolute bottom-4 w-full"
          >
            <div className="flex items-center justify-between">
              <p className="text-xl font-bold">Subtotal</p>
              <p className="text-xl font-bold">${subtotal}</p>
            </div>
            <Button
              className="bg-black text-white rounded bg-opacity-95 mt-4 w-full"
              type="submit"
              isDisabled={disableCta}
            >
              {ctaText}
            </Button>
          </FadeInSlide>
        )}
      </div>
    </form>
  );
};
