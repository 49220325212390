import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useHistoryTracker = () => {
  const [history, setHistory] = useState<any>([]);
  const location = useLocation();

  useEffect(() => {
    setHistory((prevHistory: any) => [...prevHistory, location]);
  }, [location]);

  return history;
};
