import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { GradientBackground } from "app/animations/gradientBackground/gradientBackground";
import { Path } from "app/path";
import * as React from "react";

import AsyncSelect from "react-select/async";
import { useHome } from "./useHome";
import { AccountSearchBar } from "app/components/accountSearchbar/accountSearchBar";

export interface HomeProps {}

export const Home: React.FC<HomeProps> = (props) => {
  const {} = useHome();
  return (
    <>
      <div className="flex justify-center items-center bg-gray-300 w-full h-screen">
        <div className="z-10 gap-4 flex flex-col w-1/2">
          <h1 className="text-4xl font-thin text-white">All Set</h1>
          <AccountSearchBar />
        </div>
        <div className="absolute top-0 left-0">
          <GradientBackground />
        </div>
      </div>
    </>
  );
};
