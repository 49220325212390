import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "@nextui-org/react";
import * as React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export interface PaymentModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  isPaying: boolean;
}

export const PaymentModal: React.FC<PaymentModalProps> = ({
  isOpen,
  onOpenChange,
  isPaying,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} disableAnimation>
        <ModalContent className="flex flex-col gap-10">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 items-center">
                {isPaying ? "Booking Appointment" : "Appointment Booked"}
              </ModalHeader>
              <ModalBody>
                {isPaying ? (
                  <div className="flex flex-col items-center gap-4">
                    <Spinner size="lg" />
                    <p>Scheduling you in</p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 items-center">
                    <CheckCircleIcon className="h-20 w-20 fill-green-400" />
                    <p>Appointment successfully booked</p>
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="flex items-center justify-center">
                {/* <Button variant="solid" onPress={onClose}>
                  Close
                </Button> */}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
