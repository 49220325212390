import * as React from "react";
import classnames from "classnames";
import { Spinner } from "@nextui-org/react";

export interface LoadingProps {
  fullScreen?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ fullScreen = false }) => {
  return (
    <div
      className={classnames("relative", {
        "h-screen": fullScreen,
        "h-full": !fullScreen,
      })}
    >
      <Spinner size="lg" />
    </div>
  );
};
