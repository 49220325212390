import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface ScheduleSliceState {
  date: any;
  availableTimeSlots: any[];
  availability: any;
  appointments: any[];
}

const initialState = {} as ScheduleSliceState;

export const getSchedule = createAsyncThunk(
  "schedule/getScheduleStatus",
  async ({ employeeId, date }: { employeeId: number; date: any }, thunkAPI) => {
    try {
      const { data } = await http.get(`/v1/schedules/${employeeId}`, {
        params: {
          date: date.toISOString().split("T")[0],
          snakeCase: true,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const createSchedule = createAsyncThunk(
// );

// export const updateSchedule = createAsyncThunk(
// );

// export const deleteSchedule = createAsyncThunk(
// );

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const selectSchedule = (state: RootState) => state.schedule;
