import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface UserSliceState {
  id: number;
  name: string;
  email: string;
  account: any;
  archivedAt?: string;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = {} as UserSliceState;

export const getUser = createAsyncThunk(
  "user/getUserStatus",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/users");
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userSignup = createAsyncThunk(
  "user/signupStatus",
  async ({ email, name, authProvider }: any, thunkAPI) => {
    try {
      const response = await http.post("/users", {
        user: {
          email,
          name,
          authProvider,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userUpdate = createAsyncThunk(
  "user/updateStatus",
  async (user: any, thunkAPI) => {
    try {
      const { data } = await http.put("/users", {
        user,
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const archiveUser = createAsyncThunk(
  "user/archiveUserStatus",
  async (_: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/users/archive`, {});
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unArchiveUser = createAsyncThunk(
  "user/unArchiveUserStatus",
  async ({ id }: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/v1/users/unarchive`, {
        user: {
          id,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(userSignup.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(userUpdate.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectUser = (state: RootState) => state.user;
