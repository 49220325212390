export enum Path {
  HOME = "/",
  EXPLORE = "/book",
  BOOK = "/book/:nameKey",
  BOOK_WITH_SHOP = "/book/:nameKey/:shopKey",
  BOOK_SERVICE = "/book/:nameKey/:shopKey/:serviceProviderKey/service",
  BOOK_APPOINTMENT = "/book/:nameKey/:shopKey/:serviceProviderKey/schedule",
  SIGNUP = "/signup",
  SIGNIN = "/signin",
  SETTINGS = "/settings",
}
