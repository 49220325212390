import * as React from "react";
import { useBookAppointment } from "./useBookAppointment";
import { OrderCard } from "app/book/components/orderCard";
import { Loading } from "app/components/loading/loading";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import { Button, Skeleton } from "@nextui-org/react";
import classNames from "classnames";
import { PaymentModal } from "../components/paymentModal";

export interface BookAppointmentProps {}

export const BookAppointment: React.FC<BookAppointmentProps> = (props) => {
  const {} = props;
  const {
    paramShopKey,
    serviceProvider,
    mainServiceChoice,
    additionalServices,
    onHandleSubmit,
    isLoading,
    cartServices,
    selectedDate,
    setSelectedDate,
    timeSlots,
    selectedTimeSlot,
    setSelectedTimeSlot,
    onHandleTipSelect,
    subtotal,
    showPaymentModal,
    setShowPaymentModal,
    isPaying,
    currentShopTime,
    timezone,
  } = useBookAppointment();

  const defaultClassNames = getDefaultClassNames();
  const formattedDate = selectedDate
    ? new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(selectedDate)
    : null;

  return (
    <section className="flex h-[95vh]">
      <div className="flex flex-col w-2/3 gap-4 p-4 mt-10">
        <p className="text-xl font-bold mb-8">Choose a time</p>
        <div className="flex gap-1">
          {selectedDate && (
            <div className="flex gap-1">
              <p className="font-bold">Selected:</p>
              <p>
                {selectedDate
                  ? `${formattedDate}  ${
                      selectedTimeSlot
                        ? ` at ${selectedTimeSlot.split(" - ")[0]}`
                        : ""
                    }`
                  : "Pick a day."}
              </p>
            </div>
          )}
          {!selectedDate && <p>Pick a day.</p>}
        </div>
        <div className="mb-10">
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            today={currentShopTime}
            className="w-full shadow-lg p-4 border rounded items-center justify-center"
            numberOfMonths={2}
            pagedNavigation
            disabled={[
              { before: currentShopTime }, // Disable past dates
            ]}
            classNames={{
              selected: "bg-black rounded !text-white",
              chevron: "fill-black",
              root: `${defaultClassNames.root} flex`,
              today: "text-blue-500",
            }}
          />
          {selectedDate && timeSlots.length > 0 && (
            <div className="flex justify-between relative mt-10">
              <div className="flex flex-col gap-4">
                <p className="mt-4 font-bold">Available Time Slots:</p>
                {isLoading ? (
                  <div className="w-full flex flex-col gap-2">
                    <Skeleton className="h-10 w-4/5 rounded-lg" />
                    <Skeleton className="h-10 w-3/5 rounded-lg" />
                  </div>
                ) : (
                  <ul className="flex gap-4 flex-wrap">
                    {timeSlots.map((slot: any, index: number) => (
                      <Button
                        key={index}
                        variant="bordered"
                        className={classNames("p-4 shadow-md border-1", {
                          "bg-black text-white": selectedTimeSlot === slot,
                        })}
                        onClick={() => setSelectedTimeSlot(slot)}
                      >
                        {slot.split(" - ")[0]}
                      </Button>
                    ))}
                  </ul>
                )}
              </div>
              <p className="absolute top-3 right-6 text-xs">
                {timezone.timeZoneName}
              </p>
            </div>
          )}
          {selectedDate && timeSlots.length === 0 && (
            <div className="flex flex-col gap-4 mt-10">
              <p className="mt-4 font-bold text-red-500">
                No available time slots for the selected day.
              </p>
            </div>
          )}
        </div>
      </div>
      <OrderCard
        paramShopKey={paramShopKey}
        serviceProvider={serviceProvider}
        mainServiceChoice={mainServiceChoice}
        additionalServices={additionalServices}
        subtotal={subtotal}
        onHandleSubmit={onHandleSubmit}
        disableCta={!selectedDate || !selectedTimeSlot}
        parentHandleTipSelect={onHandleTipSelect}
        ctaText="Book Appointment"
        // showTip
      />
      <PaymentModal
        isOpen={showPaymentModal}
        onOpenChange={setShowPaymentModal}
        isPaying={isPaying}
      />
    </section>
  );
};
