export const getSubTotal = (services: any[]) => {
  if (!services.length) return "";
  const totalCents = services.reduce(
    (acc: number, service: any) => acc + service.priceCents,
    0
  );

  // Convert back to dollars and format
  const totalDollars = (totalCents / 100).toFixed(2);

  return totalDollars;
};
