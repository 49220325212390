import { startCase, replace } from "lodash";

export const titleize = (value?: string) => {
  if (!value) return "";
  return startCase(replace(value, "_", " "));
};

export const parameterize = (str: string): string => {
  return str
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^a-z0-9\s-]/g, "") // Remove non-alphanumeric characters except spaces and hyphens
    .trim() // Trim leading/trailing whitespace
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Remove consecutive hyphens
};
