import { Path } from "app/path";
import * as React from "react";
import { Link } from "react-router-dom";

export interface NotFoundProps {}

export const NotFound: React.FC<NotFoundProps> = (props) => {
  const {} = props;
  return (
    <section className="w-full">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-gray-900">
            404
          </h1>
          <p className="mb-4 text-3xl font-public tracking-tight font-bold text-gray-900 md:text-3xl dark:text-white">
            The page you're looking for can't be found.
          </p>
          <Link to={Path.HOME} className="btn-blue">
            Return to Home
          </Link>
        </div>
      </div>
    </section>
  );
};
