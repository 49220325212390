import { getAccount, selectAccount } from "app/redux/accountSlice";
import { titleize } from "app/utils/string";
import { format } from "date-fns";
import { find, findIndex } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface Availability {
  id: number;
  day: string;
  timeStart: string;
  timeEnd: string;
  availabilityableType: string;
  availabilityableId: number;
  createdAt: string;
  updatedAt: string;
}

const getServiceProviders = ({ account, shopKey }: any) =>
  find(account.shops, (sp) => sp.key === shopKey).serviceProviders;

export const useBookWithShop = () => {
  const { nameKey, shopKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceProviders, setServiceProviders] = useState([]);

  const getNextAvailabilityDay = (availabilities: Availability[]): string => {
    const now = new Date();
    const currentDay = now.getDay(); // 0 (Sunday) to 6 (Saturday)
    const currentTime = now.getTime();
    const daysOfWeek = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    // Create a new array for sorting to avoid modifying the original array
    const sortedAvailabilities = [...availabilities].sort((a, b) => {
      if (a.day === b.day) {
        return (
          new Date(`1970-01-01T${a.timeStart}`).getTime() -
          new Date(`1970-01-01T${b.timeStart}`).getTime()
        );
      }
      return daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day);
    });

    let result = "No upcoming availabilities"; // Default message if no time is found

    // Iterate over days of the week starting from today
    for (let i = 0; i < 7; i++) {
      const dayToCheck = (currentDay + i) % 7;
      const dayName = daysOfWeek[dayToCheck];

      // Check all availabilities for the current day to check
      for (const availability of sortedAvailabilities) {
        if (availability.day === dayName) {
          const startTime = new Date(now);
          const [startHour, startMinute] = availability.timeStart.split(":");
          startTime.setHours(
            parseInt(startHour, 10),
            parseInt(startMinute, 10),
            0,
            0
          );

          const endTime = new Date(now);
          const [endHour, endMinute] = availability.timeEnd.split(":");
          endTime.setHours(
            parseInt(endHour, 10),
            parseInt(endMinute, 10),
            0,
            0
          );

          // Check if the availability is valid
          if (i === 0 && currentTime < endTime.getTime()) {
            result =
              currentTime < startTime.getTime()
                ? `Today at ${format(startTime, "hh:mm a")}`
                : `Today at ${format(endTime, "hh:mm a")}`;
            break;
          } else if (i > 0) {
            result = `${titleize(dayName)} at ${format(startTime, "hh:mm a")}`;
            break;
          }
        }
      }

      // Break out of the outer loop once a valid availability is found
      if (result !== "No upcoming availabilities") {
        break;
      }
    }

    return result;
  };

  const onGoBackClick = () => {
    const storePath = location.pathname.split("/").slice(0, -1).join("/");
    navigate(storePath);
  };

  useEffect(() => {
    if (!nameKey) return;
    if (account.shops) {
      setServiceProviders(getServiceProviders({ account, shopKey }));
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const account = await dispatch(getAccount({ nameKey }) as any).unwrap();
        setServiceProviders(getServiceProviders({ account, shopKey }));
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    isLoading,
    serviceProviders,
    getNextAvailabilityDay,
    paramName: nameKey,
    paramShopKey: shopKey,
    location,
    onGoBackClick,
  };
};
