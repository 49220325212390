import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { RootState } from "./store";

export interface CartSliceState {
  id: number;
  uid: string;
  serviceProvider: any;
  status: string;
  merchantServices: any[];
  shop: any;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = {} as CartSliceState;

export const getCart = createAsyncThunk(
  "cart/getCartStatus",
  async ({ uid }: { uid: string }, thunkAPI) => {
    try {
      const { data } = await http.get(`/v1/carts/${uid}`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCart = createAsyncThunk(
  "cart/createCartStatus",
  async (
    {
      serviceProviderId,
      merchantServiceIds,
      userId,
      shopId,
    }: {
      serviceProviderId: number;
      merchantServiceIds: number[];
      shopId: number;
      userId?: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/carts`, {
        cart: {
          serviceProviderId,
          merchantServiceIds,
          userId,
          shopId,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(createCart.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectCart = (state: RootState) => state.cart;
