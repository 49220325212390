import { Path } from "app/path";
import { getAccount, selectAccount } from "app/redux/accountSlice";
import { createCart } from "app/redux/cartSlice";
import { find, findIndex } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";

export const getFormatedDuration = (service: any) =>
  service.duration > 60
    ? `${service.duration / 60} hr`
    : `${service.duration} mins`;

const getServiceProviderServices = ({ account, serviceProviderKey }: any) => {
  const serviceProvider = find(
    account.employees,
    (employee: any) => employee.key === serviceProviderKey
  );
  const services = serviceProvider.services;

  return {
    serviceProvider,
    services,
  };
};

export const useBookService = () => {
  const { nameKey, shopKey, serviceProviderKey }: any = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const account = useSelector(selectAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [providedServices, setprovidedServices] = useState([]);
  const [mainServiceChoice, setMainServiceChoice] = useState<any>(null);
  const [additionalServices, setAdditionalServices] = useState<any>([]);
  const [serviceProvider, setServiceProvider] = useState<any>(null);

  const handleServiceClick = (service: any) => {
    if (!mainServiceChoice) {
      setMainServiceChoice(service);
    } else {
      const index = findIndex(additionalServices, { id: service.id });
      if (index === -1) {
        setAdditionalServices([...additionalServices, service]);
      } else {
        setAdditionalServices(
          additionalServices.filter((s: any) => s.id !== service.id)
        );
      }
    }
  };

  const onHandleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const shop = find(account.shops, { key: shopKey });
      const serviceProviderId = serviceProvider.id;

      const merchantServiceIds = [
        mainServiceChoice.id,
        ...additionalServices.map((service: any) => service.id),
      ];

      // const userId = account.id;
      const cart = await dispatch(
        createCart({
          serviceProviderId,
          merchantServiceIds,
          shopId: shop.id,
        }) as any
      ).unwrap();
      const path = generatePath(Path.BOOK_APPOINTMENT, {
        nameKey,
        shopKey,
        serviceProviderKey,
      });

      const url = `${path}?cart=${cart.uid}?from=bookService`;
      navigate(url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!nameKey) return;
    if (account.shops) {
      const { serviceProvider, services } = getServiceProviderServices({
        account,
        serviceProviderKey,
      });
      const availableServices = services.filter(
        (service: any) => service.status === "active"
      );
      setprovidedServices(availableServices);
      setServiceProvider(serviceProvider);
      return;
    }
    (async () => {
      try {
        setIsLoading(true);
        const account = await dispatch(getAccount({ nameKey }) as any).unwrap();
        const { services, serviceProvider } = getServiceProviderServices({
          account,
          serviceProviderKey,
        });
        const availableServices = services.filter(
          (service: any) => service.status === "active"
        );
        setprovidedServices(availableServices);
        setServiceProvider(serviceProvider);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    isLoading,
    paramName: nameKey,
    paramShopKey: shopKey,
    providedServices,
    mainServiceChoice,
    setMainServiceChoice,
    additionalServices,
    setAdditionalServices,
    handleServiceClick,
    serviceProvider,
    onHandleSubmit,
  };
};
