import axios, { AxiosInstance } from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { includes } from "lodash";
import firebase from "app/utils/firebase";
import { toast } from "react-toastify";

// Default config options
const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
const http: AxiosInstance = axios.create(defaultOptions);

// // Set the AUTH token for any request
http.interceptors.request.use(async (config) => {
  const token = await firebase.currentUser?.getIdToken();

  config.headers!.Authorization = token ? `Bearer ${token}` : "";

  if (config.params?.snakeCase === true) {
    // Get calls that have more than one param need to be snake cased to be read by the backend
    config.params = snakecaseKeys(config.params, { deep: true });
  }

  if (!includes(["get", "delete"], config.method)) {
    config.data = snakecaseKeys(config.data, { deep: true });
  }

  return config;
});

http.interceptors.response.use(
  function (response) {
    const newResponse = { ...response };
    newResponse.data = camelcaseKeys(newResponse.data, { deep: true });
    return newResponse;
  },
  function (error) {
    if (
      error.response.data ===
      "You need to sign in or sign up before continuing."
    ) {
      if (window.screen.width > 768) {
        toast.error("You need to login before continuing.");
      }
    }
    console.log(error);
    // Do something with response error
    return Promise.reject(error.response);
  }
);

export default http;
