import * as React from "react";
import { motion } from "framer-motion";

export interface FadeInSlideProps {
  direction: "left" | "right" | "bottom" | "top";
  children: React.ReactNode;
  className?: string;
  key: string; // Added key prop
}

const directionVariants = {
  left: {
    hidden: { opacity: 0, x: "10%" },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "-100%" },
  },
  right: {
    hidden: { opacity: 0, x: "-10%" },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: "100%" },
  },
  bottom: {
    hidden: { opacity: 0, y: "10%" },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: "-100%" },
  },
  top: {
    hidden: { opacity: 0, y: "-10%" },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: "100%" },
  },
};

export const FadeInSlide: React.FC<FadeInSlideProps> = ({
  direction,
  children,
  className,
  key,
}) => {
  return (
    <motion.div
      key={key} // Utilize the key prop
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={directionVariants[direction]}
      transition={{ duration: 0.5 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};
