import { getAccount, selectAccount } from "app/redux/accountSlice";
import { useQuery } from "app/utils/useQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const useBook = () => {
  const { nameKey } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const account = useSelector(selectAccount);
  const [showLocations, setShowLocations] = useState(false);
  const [shopHovered, setShopHovered] = useState(null);

  const onBookNowClick = () => {
    setShowLocations(true);
  };

  useEffect(() => {
    if (!nameKey) return;
    (async () => {
      try {
        setIsLoading(true);
        await dispatch(getAccount({ nameKey }) as any).unwrap();
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        navigate("/");
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    showLocations,
    account,
    onBookNowClick,
    shopHovered,
    setShopHovered,
    navigate,
    isLoading,
  };
};
