import * as React from "react";
import { useBookWithShop } from "./useBookWithShop";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { Link, generatePath } from "react-router-dom";
import { Path } from "app/path";
import classNames from "classnames";
import { Avatar, Button, Image } from "@nextui-org/react";
import { Loading } from "app/components/loading/loading";

export interface BookWithShopProps {}

export const BookWithShop: React.FC<BookWithShopProps> = (props) => {
  const {} = props;
  const {
    serviceProviders,
    isLoading,
    getNextAvailabilityDay,
    paramName,
    paramShopKey,
    location,
    onGoBackClick,
  } = useBookWithShop();

  if (isLoading) {
    return (
      <div className="absolute left-1/2 top-1/2">
        <Loading fullScreen />;
      </div>
    );
  }

  return (
    <section className="flex h-screen">
      <div className="flex flex-col w-2/3 gap-4 p-4 mt-10 ">
        <p className="text-xl font-bold mb-10">Choose a professional</p>
        <div className="flex gap-4 flex-wrap relative">
          {serviceProviders.length === 0 && (
            <div className="flex flex-col gap-4">
              <p>No service providers currently at this location</p>
              <Button
                className="rounded bg-black text-white w-[200px]"
                onClick={() => onGoBackClick()}
              >
                Go back
              </Button>
            </div>
          )}

          {serviceProviders?.map((sp: any, index: number) => {
            const firstName = sp.name.split(" ")?.[0] || sp.name;
            const lastNameFirstLetter = sp.name.split(" ")?.[1]?.[0] || "";
            const nextAvailabilityDay = getNextAvailabilityDay(
              sp.availabilities
            );
            return (
              <FadeInSlide key={sp.id} direction="top">
                <Link
                  to={generatePath(Path.BOOK_SERVICE, {
                    nameKey: paramName!,
                    shopKey: paramShopKey!,
                    serviceProviderKey: sp.key,
                  })}
                  key={sp.id}
                  className={classNames(
                    "flex flex-col w-[200px] items-center gap-4 border rounded p-4 border-gray-200 shadow-lg transition duration-400 hover:cursor-pointer hover:bg-gray-300"
                  )}
                >
                  <Avatar
                    src={sp.avatar}
                    alt={`Logo of ${sp.name}`}
                    size="lg"
                    className="grayscale rounded"
                  />

                  <div className="flex flex-col text-center gap-1">
                    <p className="text-black font-bold">
                      {firstName} {lastNameFirstLetter}
                    </p>
                    <p className="text-gray-400 text-xs">Availabile</p>
                    <p className="text-gray-400 text-xs">
                      {nextAvailabilityDay}
                    </p>
                  </div>
                </Link>
              </FadeInSlide>
            );
          })}
        </div>
      </div>
    </section>
  );
};
