import React from "react";
import { components } from "react-select";

// Custom option component for AsyncSelect
const CustomAccountOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-3">
        {/* Logo image on the left */}
        <img
          src={props.data.logo}
          alt={`${props.data.label} logo`}
          className="w-10 h-10 object-cover"
        />
        {/* Account name on the right */}
        <span>{props.data.label}</span>
      </div>
    </components.Option>
  );
};

export default CustomAccountOption;
