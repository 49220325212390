import { accountSearch } from "app/redux/accountSlice";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useAccountSearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAccountSearch = async (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      try {
        const response = await dispatch(
          accountSearch({ query: inputValue }) as any
        ).unwrap();
        const results =
          response.accounts?.map((c: any) => ({
            value: c.id,
            label: c.name,
            logo: c.logo,
            bookingLink: c.bookingLink,
          })) || [];
        callback(results);
      } catch (error) {
        console.error("Error searching clients:", error);
      }
    } else {
      return [];
    }
  };

  return {
    navigate,
    handleAccountSearch,
  };
};
