import * as React from "react";
import { motion } from "framer-motion";
import { useBook } from "./useBook";
import { Button, Image, Spinner } from "@nextui-org/react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import Map, { Marker } from "react-map-gl";
import classNames from "classnames";
import { Link, generatePath } from "react-router-dom";
import { Path } from "app/path";
import { FadeInSlide } from "app/motions/fadeInSlide";
import HairStudioPlaceHolderImg from "app/assets/images/hairStudioPlaceholder.jpg";
import LandingImagePlaceholderImg from "app/assets/images/landingImagePlaceholder.jpg";

export interface BookProps {}

export const Book: React.FC<BookProps> = (props) => {
  const {} = props;
  const {
    account,
    showLocations,
    onBookNowClick,
    shopHovered,
    setShopHovered,
    navigate,
    isLoading,
  } = useBook();

  if (isLoading) {
    return (
      <Spinner size="lg" color="primary" className="fixed top-1/3 left-1/2" />
    );
  }

  return (
    <div>
      {showLocations ? (
        <section className="flex">
          <FadeInSlide key="locations" direction="left" className=" w-[570px] ">
            <div className="flex flex-col max-w-[357px] gap-4 p-4 mt-10">
              <p className="text-xl font-bold mb-10">Choose a location</p>
              {account.shops?.map((shop) => {
                const isShopHovered = shopHovered === shop.id;
                const isComplete =
                  shop?.latitude && shop?.longitude && shop.addressLine1;
                return (
                  <Link
                    to={generatePath(Path.BOOK_WITH_SHOP, {
                      nameKey: account.key,
                      shopKey: shop.key,
                    })}
                    key={shop.id}
                    className={classNames(
                      "flex gap-4 border rounded p-4 border-gray-200 shadow-lg transition duration-400 hover:cursor-pointer hover:bg-gray-300",
                      {
                        "bg-gray-300": isShopHovered,
                        "pointer-events-none opacity-50": !isComplete,
                      }
                    )}
                  >
                    <div className="flex flex-col gap-1">
                      <p className="text-black font-bold">{shop.name}</p>
                      {isComplete ? (
                        <>
                          <p className="text-xs font-light">
                            {shop.addressLine1}
                          </p>
                          <p className="text-xs font-light">
                            {shop.addressLine2}
                          </p>
                        </>
                      ) : (
                        <p className="text-black text-sm">Coming soon</p>
                      )}
                    </div>
                  </Link>
                );
              })}
            </div>
          </FadeInSlide>
          <div className={classNames("w-full relative h-screen")}>
            <Map
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              initialViewState={{
                longitude: account.shops[0].longitude,
                latitude: account.shops[0].latitude,
                zoom: 8,
              }}
              // style={{ width: "100%", height: "100%" }}
              mapStyle="mapbox://styles/mapbox/light-v11"
            >
              {account.shops.map(
                ({ id, longitude, latitude, key }: any, index: number) => (
                  <Marker
                    longitude={longitude}
                    latitude={latitude}
                    style={
                      {
                        // zIndex:
                        //   !!selectedMarkerId &&
                        //   selectedMarkerId !== marker.mechanicAccount.id
                        //     ? 10
                        //     : 20,
                      }
                    }
                    key={index}
                  >
                    <MapPinIcon
                      onMouseOver={() => setShopHovered(id)}
                      onMouseLeave={() => setShopHovered(null)}
                      className={classNames(
                        "w-8 h-8 hover:cursor-pointer border-none fill-slate-400 stroke-slate-800 text-white duration-200 hover:scale-125 hover:fill-slate-700 hover:stroke-slate-700",
                        {}
                      )}
                      onClick={() =>
                        navigate(
                          generatePath(Path.BOOK_WITH_SHOP, {
                            nameKey: account.key,
                            shopKey: key,
                          })
                        )
                      }
                    />
                  </Marker>
                )
              )}
            </Map>
          </div>
        </section>
      ) : (
        <section className="flex flex-col gap-4">
          <div className="flex flex-col gap-8 justify-center h-full max-w-[570px] p-10 absolute left-0 top-0 z-30 bg-white bg-opacity-80">
            <FadeInSlide
              key="locations"
              direction="left"
              className="flex flex-col gap-4"
            >
              <Image
                src={account.logo || HairStudioPlaceHolderImg}
                alt={`Logo of ${account.name}`}
                className="w-32 h-32"
              />
              <p className="text-4xl uppercase font-bold">{account.name}</p>
              <div className="flex flex-col gap-4">
                <p className="text-sm text-gray-500 font-light">
                  {account.description}
                </p>
                <div className="flex items-center gap-1 w-full">
                  <MapPinIcon className="h-4 w-4" />
                  <p className="text-sm text-gray-500 font-light">
                    {account.shops?.length} locations
                  </p>
                </div>
              </div>
              <Button
                className="bg-black rounded bg-opacity-95 mt-8"
                onClick={onBookNowClick}
              >
                <p className="text-xs text-white">Book now</p>
              </Button>
            </FadeInSlide>
          </div>
          <Image
            src={account.selectedLandingImageLink || LandingImagePlaceholderImg}
            alt="Barbershop"
            className="object-cover rounded-none h-screen w-screen"
          />
        </section>
      )}
    </div>
  );
};
