import { configureStore } from "@reduxjs/toolkit";
import { usersSlice } from "./userSlice";
import { accountSlice } from "./accountSlice";
import { cartSlice } from "./cartSlice";
import { appointmentsSlice } from "./appointmentsSlice";
import { scheduleSlice } from "./scheduleSlice";

export const store = configureStore({
  reducer: {
    user: usersSlice.reducer,
    account: accountSlice.reducer,
    cart: cartSlice.reducer,
    appointments: appointmentsSlice.reducer,
    schedule: scheduleSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UserState}
export type AppDispatch = typeof store.dispatch;
