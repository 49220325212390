import * as React from "react";
import { getFormatedDuration, useBookService } from "./useBookService";
import { FadeInSlide } from "app/motions/fadeInSlide";
import classNames from "classnames";
import { findIndex } from "lodash";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { OrderCard } from "app/book/components/orderCard";
import { getSubTotal } from "app/book/utils/getSubTotal";
import { Loading } from "app/components/loading/loading";

export interface BookServiceProps {}

export const BookService: React.FC<BookServiceProps> = ({}) => {
  const {
    providedServices,
    mainServiceChoice,
    setMainServiceChoice,
    additionalServices,
    handleServiceClick,
    paramShopKey,
    serviceProvider,
    onHandleSubmit,
    isLoading,
  } = useBookService();

  const subtotal = getSubTotal([
    ...(mainServiceChoice ? [mainServiceChoice] : []),
    ...(additionalServices || []),
  ]);

  if (isLoading) {
    return (
      <div className="absolute left-1/2 top-1/3">
        <Loading />
      </div>
    );
  }

  return (
    <section className="flex h-[80vh]">
      <div className="flex flex-col w-2/3 gap-4 p-4 mt-10">
        <p className="text-xl font-bold mb-8">Choose a service</p>
        <div className="mb-10">
          {mainServiceChoice && (
            <FadeInSlide key={mainServiceChoice.id} direction="top">
              <div
                onClick={() => setMainServiceChoice(null)}
                key={mainServiceChoice.id}
                className={classNames(
                  "group flex flex-col w-[200px] relative items-start gap-4 border rounded p-4 bg-black cursor-pointer"
                )}
              >
                <div className="absolute -top-[12px] -right-2 bg-white text-black p-2 rounded-full border border-black">
                  <FadeInSlide key={mainServiceChoice.id} direction="top">
                    <CheckIcon
                      className="h-4 w-4 group-hover:hidden"
                      style={{ strokeWidth: 3 }}
                    />
                    <XMarkIcon
                      className="h-4 w-4 hidden group-hover:block"
                      style={{ strokeWidth: 3 }}
                    />
                  </FadeInSlide>
                </div>
                <div className="flex flex-col gap-8 w-full">
                  <div className="flex flex-col gap-1">
                    <p className="text-white text-xs font-bold">
                      {mainServiceChoice.title}
                    </p>
                    <p className="text-xs font-light text-white">
                      {getFormatedDuration(mainServiceChoice)}
                    </p>
                  </div>
                  <div className="flex w-full justify-end">
                    <div className="bg-gray-200 absolute bottom-3 right-[1px] p-1 px-3 rounded-tl rounded-bl">
                      <p className="text-black font-semibold text-xs">
                        {mainServiceChoice.price.split(".")[0]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </FadeInSlide>
          )}
        </div>
        {mainServiceChoice && (
          <p className="text-xl font-bold mb-8">
            Anything else you want to add?
          </p>
        )}
        <div className="flex gap-4 flex-wrap">
          {providedServices.map((service: any) => {
            if (mainServiceChoice && mainServiceChoice.id === service.id)
              return;

            return (
              <FadeInSlide
                key={service.id}
                direction="top"
                className="shadow-lg"
              >
                <div
                  onClick={() => handleServiceClick(service)}
                  key={service.id}
                  className={classNames(
                    "flex flex-col w-[200px] items-start gap-4 border rounded p-4 border-gray-200 transition duration-400 hover:cursor-pointer hover:bg-gray-300",
                    {
                      "bg-gray-300":
                        findIndex(additionalServices, { id: service.id }) !==
                        -1,
                    }
                  )}
                >
                  <div className="flex flex-col gap-8 w-full">
                    <div className="flex flex-col gap-1">
                      <p className="text-black text-xs font-bold">
                        {service.title}
                      </p>
                      <p className="text-xs font-light">
                        {getFormatedDuration(service)}
                      </p>
                    </div>
                    <div className="flex w-full justify-end">
                      <div className="bg-gray-200 absolute bottom-3 right-[1px] p-1 px-3 rounded-tl rounded-bl">
                        <p className="text-black font-semibold text-xs">
                          {service.price.split(".")[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </FadeInSlide>
            );
          })}
        </div>
      </div>
      <OrderCard
        paramShopKey={paramShopKey}
        serviceProvider={serviceProvider}
        mainServiceChoice={mainServiceChoice}
        additionalServices={additionalServices}
        subtotal={subtotal}
        onHandleSubmit={onHandleSubmit}
        ctaText="Choose a time"
      />
    </section>
  );
};
