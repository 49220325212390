import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getCart, selectCart } from "app/redux/cartSlice";
import { createAppointment } from "app/redux/appointmentsSlice";
import { getSchedule } from "app/redux/scheduleSlice";
import { getSubTotal } from "../utils/getSubTotal";
import { delay } from "app/utils/delay";
import { useQuery } from "app/utils/useQuery";
import { Path } from "app/path";
import { format, toZonedTime } from "date-fns-tz";
import { getUtcTime } from "app/utils/date";
import { getFormValues } from "app/utils/getFormValues";
import { titleize } from "app/utils/string";
import cable from "app/cable";

enum CartStatus {
  COMPLETED = "completed",
  PENDING = "pending",
}

// Calculate the total duration of the services in the cart
const calculateTotalDuration = (services: any[]) => {
  return services.reduce((total, service) => total + service.duration, 0);
};

// Generate time slots from start to end time
const generateTimeSlots = (
  timeStart: string,
  timeEnd: string,
  interval: number
) => {
  const slots = [];
  let [startHour, startMinute] = timeStart.split(":").map(Number);
  let [endHour, endMinute] = timeEnd.split(":").map(Number);

  while (
    startHour < endHour ||
    (startHour === endHour && startMinute + interval <= endMinute)
  ) {
    const slotStart = `${String(startHour).padStart(2, "0")}:${String(
      startMinute
    ).padStart(2, "0")}`;
    startMinute += interval;

    if (startMinute >= 60) {
      startHour += 1;
      startMinute -= 60;
    }

    const slotEnd = `${String(startHour).padStart(2, "0")}:${String(
      startMinute
    ).padStart(2, "0")}`;
    slots.push(`${slotStart} - ${slotEnd}`);
  }

  return slots;
};

// Filter time slots based on appointments and total duration
const filterTimeSlots = (
  slots: string[],
  appointments: any[],
  totalDuration: number,
  currentDate: Date,
  isSameDay: boolean
) => {
  // Calculate the current time in minutes from midnight
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  return slots.filter((slot, index, arr) => {
    const [slotStartStr, slotEndStr] = slot.split(" - ");
    const [slotStartHour, slotStartMinute] = slotStartStr
      .split(":")
      .map(Number);

    // Convert start time to minutes from midnight
    const slotStartTime = slotStartHour * 60 + slotStartMinute;

    // Skip slots that are before the current time if it's the same day
    if (isSameDay && slotStartTime < currentTimeInMinutes) {
      return false;
    }

    // Check if there are enough slots left from the current slot to fit the duration
    const remainingSlots = arr.slice(index);
    const remainingDuration = remainingSlots.reduce((acc, currSlot) => {
      const [startStr, endStr] = currSlot.split(" - ");
      const [startHour, startMinute] = startStr.split(":").map(Number);
      const [endHour, endMinute] = endStr.split(":").map(Number);
      const slotStart = startHour * 60 + startMinute;
      const slotEnd = endHour * 60 + endMinute;
      return acc + (slotEnd - slotStart);
    }, 0);

    if (remainingDuration < totalDuration) return false;

    // Calculate the end time of the new appointment
    const appointmentEndTime = slotStartTime + totalDuration;

    // Format the appointment end time to compare with existing appointments
    const endHour = Math.floor(appointmentEndTime / 60);
    const endMinute = appointmentEndTime % 60;
    const appointmentEndFormatted = `${String(endHour).padStart(
      2,
      "0"
    )}:${String(endMinute).padStart(2, "0")}`;

    // Ensure the slot doesn't overlap with existing appointments
    return appointments.every((appointment) => {
      const appointmentStart = appointment.startTime.slice(11, 16);
      const appointmentEnd = appointment.endTime.slice(11, 16);

      return (
        appointmentEndFormatted <= appointmentStart ||
        slotStartStr >= appointmentEnd
      );
    });
  });
};

export const useBookAppointment = () => {
  const { nameKey, shopKey, serviceProviderKey }: any = useParams();
  const query = useQuery();
  const cartUid = query.get("cart");
  const from = query.get("from");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector(selectCart);
  const [schedule, setSchedule] = useState<any>();
  const timezone = cart?.shop?.timezone;
  const currentShopTime = toZonedTime(new Date(), timezone?.timeZoneId);
  const [isLoading, setIsLoading] = useState(false);
  const [cartServices, setCartServices] = useState([]);
  const [serviceProvider, setServiceProvider] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();
  const [tipPercentage, setTipPercentage] = useState<number>(1);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const subtotalNumber = parseFloat(getSubTotal(cartServices));
  const subtotal = tipPercentage
    ? (subtotalNumber + subtotalNumber * (tipPercentage / 100)).toFixed(2)
    : subtotalNumber.toFixed(2);

  const totalDuration = calculateTotalDuration(cartServices);

  const onHandleSubmit = async (event: any) => {
    event.preventDefault();
    setIsPaying(true);
    setShowPaymentModal(true);

    const { name, email, phone } = getFormValues(event.target);

    try {
      let appointmentGroupId = null;

      // Extract start time from selectedTimeSlot
      let [startTime] = selectedTimeSlot!.split(" - ");

      // Extract the hours and minutes from the start time
      const [startHour, startMinute] = startTime.split(":").map(Number);

      // Calculate the initial start time in minutes from midnight
      let totalMinutes = startHour * 60 + startMinute;

      for (const service of cartServices as any) {
        // Calculate the end time by adding the service duration to the start time
        totalMinutes += service.duration;
        const endHour = Math.floor(totalMinutes / 60);
        const endMinute = totalMinutes % 60;

        // Format endHour and endMinute as strings with leading zeros if necessary
        const formattedEndHour = endHour.toString().padStart(2, "0");
        const formattedEndMinute = endMinute.toString().padStart(2, "0");

        // Get the selected date in 'YYYY-MM-DD' format
        const startDate = selectedDate!.toISOString().split("T")[0];

        // Construct the start and end time strings in the desired format
        const formattedStartTime = `${startDate}T${startTime}`;
        const formattedEndTime = `${startDate}T${formattedEndHour}:${formattedEndMinute}`;

        const appointmentData: any = {
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          shopId: cart.shop.id,
          cartUid,
          email,
          name: titleize(name),
          companyId: cart.shop.account.id,
          tipsCents: subtotalNumber * (tipPercentage! / 100) * 100,
          serviceProviderId: serviceProvider.id,
          serviceIds: [service.id],
          appointmentGroupId, // Use the same group ID for all appointments
        };

        // Create a new appointment
        const response = await dispatch(
          createAppointment(appointmentData) as any
        ).unwrap();

        // Set the appointmentGroupId for the next appointments
        if (!appointmentGroupId && response.appointmentGroup?.id) {
          appointmentGroupId = response.appointmentGroup.id;
        }

        // Update the start time for the next service
        startTime = `${formattedEndHour}:${formattedEndMinute}`;
      }

      await delay(3000);
      setIsPaying(false);
      await delay(1500);
      navigate(Path.HOME);
      setShowPaymentModal(false);
    } catch (error) {
      console.error(error);
      setIsPaying(false);
    }
  };

  const onHandleTipSelect = (percentage: number) => {
    setTipPercentage(percentage);
  };

  useEffect(() => {
    if (!cart.shop) return;
    const subscription = cable.subscriptions.create(
      {
        channel: "SchedulesChannel",
        account_id: cart.shop.account.id,
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (data) => {
          init();
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [cart.shop]);

  const init = async () => {
    const fallBackUrl = generatePath(Path.BOOK_SERVICE, {
      nameKey,
      shopKey,
      serviceProviderKey,
    });

    if (!cartUid) {
      return navigate(fallBackUrl);
    }

    try {
      setIsLoading(true);
      const { serviceProvider, merchantServices, status } = await dispatch(
        getCart({ uid: cartUid }) as any
      ).unwrap();

      if (status === CartStatus.COMPLETED) {
        if (from === "bookService") return;
        return navigate(fallBackUrl);
      }

      setCartServices(merchantServices);
      setServiceProvider(serviceProvider);

      // Check if a selected date exists
      if (selectedDate) {
        const newSchedule = await dispatch(
          getSchedule({
            employeeId: serviceProvider.id,
            date: selectedDate,
          }) as any
        ).unwrap();

        const { availability, appointments } = newSchedule;

        // Generate time slots based on availability
        const availableTimeSlots =
          availability.status === "scheduled"
            ? generateTimeSlots(
                availability.timeStart,
                availability.timeEnd,
                30 // 30 minutes interval
              )
            : [];

        const isSameDay =
          format(currentShopTime, "yyyy-MM-dd") ===
          format(selectedDate, "yyyy-MM-dd");

        const filteredTimeSlots =
          availability.status === "scheduled"
            ? filterTimeSlots(
                availableTimeSlots,
                appointments,
                totalDuration,
                currentShopTime,
                isSameDay
              )
            : [];

        setSchedule({
          ...newSchedule,
          availableTimeSlots: filteredTimeSlots,
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      // navigate(fallBackUrl);
    }
  };

  useEffect(() => {
    init();
  }, [selectedDate]);

  return {
    paramShopKey: shopKey,
    serviceProvider,
    mainServiceChoice: cartServices[0],
    additionalServices: cartServices.slice(1),
    onHandleSubmit,
    isLoading,
    cartServices,
    selectedDate,
    setSelectedDate,
    timeSlots: schedule?.availableTimeSlots || [],
    selectedTimeSlot,
    setSelectedTimeSlot,
    onHandleTipSelect,
    subtotal,
    showPaymentModal,
    setShowPaymentModal,
    isPaying,
    currentShopTime,
    timezone,
  };
};
